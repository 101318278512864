<template>
    <el-card class="order-card">
        <el-row>
            <el-col :span="6">
                <el-image class="img" fit="cover" :src="item.coverImgUrl"/>
            </el-col>
            <el-col :span="15">
                <div class="order-title">
                    {{ item.name }}
                </div>
                <div class="order-info">
                    <el-tag type="info" size="small">学习包</el-tag>
                    <p class="order-tips">
                        购买时间：{{ item.createTime }}
                    </p>
                </div>
            </el-col>
            <el-col :span="3">
                <div class="order-price">
                    <div class="price-title">
                            <span class="price-font">
                                {{ (item.price / 100).toFixed(2) }}
                            </span>
                    </div>
                    <div class="price-tips">
                        ×1
                    </div>
                </div>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
export default {
    name: "orderCard",
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        }
    }
}
</script>

<style scoped lang="scss">
.order-card {
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
        transition: all 0.2s;
        transform: translate3d(0, -5px, 0);
    }

    .img {
        border-radius: 5px;
        height: 100px;
        width: 180px;
    }

    .order-title {
        line-height: 1;
        font-weight: 400;
        font-size: 16px;
        color: #333;
        margin-top: 5px;
    }

    .order-info {
        margin-top: 30px;

        .order-tips {
            margin-top: 10px;
            color: #999;
            font-size: 12px;
        }
    }

    .order-price {
        text-align: right;

        .price-title {
            margin-top: 10px;
            height: 80px;

            .price-font {
                font-size: 24px;
                font-weight: 500;
                line-height: 1;
                color: $sr-main-color;
                padding: 0;

                &:before {
                    content: "\FFE5";
                    font-size: 16px;
                }
            }
        }

        .price-tips {
            color: #999;
            font-size: 12px;
        }
    }
}
</style>